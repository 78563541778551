export const environment = {
  apiBaseUrl: 'https://xtsapi',
  imageBaseUrl: 'https://umisimages.s3.ap-south-1.amazonaws.com/Attachments/',
  apipublicUrl: 'https://stagingtp.tnega.org/api',
  // apipublicUrl: 'https://umisapi.tnega.org', 


  tokenEndPoint: '/api/token',
  production: false,
  environment: 'Local',
  showEnvironment: true,
  indiaId: 83, //India Id used in student contact and student Info
};
