import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import MetisMenu from 'metismenujs/dist/metismenujs';
import { MenuItem } from './menu.model';
import { Router, NavigationEnd } from '@angular/router';
import { RoleService } from 'src/app/services/role.service';
import { UserSessionService } from 'src/app/services/usersession.service';
import { RoleType } from 'src/enum/roletype';
import { DataService } from 'src/app/services/data.service';
import { GlobalProvider } from 'src/app/services/backnavigation';
import { NavigationService } from 'src/app/services/navigation.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;
  // isInstitueLinkActive: boolean = false;
  // isCourseLinkActive: boolean = false;
  // isStudentLinkActive: boolean = false;
  // isHodLinkActive: boolean = false;
  isTestActive: string;
  enableMastersChildren: boolean = false;
  roleEnumType = RoleType;
  menuItems = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;
  isInstituteVerified: boolean;
  instituteCourseAvailable: boolean;
  roleId: any;
  margintop: boolean;
  isEditConsolidatedData: number;
  linkvalue: any;
  grievanceLink: any;

  constructor(@Inject(DOCUMENT) private document: Document,
    private userSessionService: UserSessionService,
    private dataService: DataService,
    public pages: GlobalProvider,
    private navigation: NavigationService,
    router: Router) {
    this.roleId = this.userSessionService.roleId();
    const storedValue = localStorage.getItem('instituteVerified');
    this.isInstituteVerified = JSON.parse(storedValue);

    const storedCourseValue = localStorage.getItem('instituteCourseAvailable');
    this.instituteCourseAvailable = JSON.parse(storedCourseValue);
    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
  }

  ngOnInit(): void {
    // this.menuItems = MENU;
    if(this.roleId == this.roleEnumType.Intsitute || this.roleId == this.roleEnumType.InstituteUser){
    this.redirectionApiCall(); 
    this.redirectionGreivanceApiCall();
// moved to only staging
    }
    
    this.menuItems = [];
    debugger
    const menu = this.userSessionService.getLocalStorageWithKey('menu');
    const menus = JSON.parse(menu);
    if (this.roleId == this.roleEnumType.Intsitute && !this.isInstituteVerified) {
      const linkToRetrieve = ['/institute', '/manualuser'];
      // const linkToRetrieve = ['/dashboard', '/institute'];
      const menuInstitute = menus.filter((x) => linkToRetrieve.includes(x.link));
      this.menuItems = menuInstitute;
    }
    else if (this.roleId == this.roleEnumType.Intsitute && this.isInstituteVerified && !this.instituteCourseAvailable) {
      const linkToRetrieve = ['/course', '/institute', '/manualuser'];
      const menuInstitute = menus.filter((x) => linkToRetrieve.includes(x.link));
      this.menuItems = menuInstitute;
    }
    else {
      this.menuItems = menus;
    }
    this.menuItems.forEach(v => {
      v.labelLength = v.label.length
      if (v.subItems) {
        v.subItems.forEach(s => {
          s.labelLength = s.label.length
        });
      }
    });
    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
  }

  marginTop(menuItems, item, i) {
    let prevItem
    if (i > 0) {
      prevItem = menuItems[i - 1];
    }
    if (prevItem && prevItem.subItems && prevItem.subItems.length > 0) {
      let item1 = prevItem.subItems[prevItem.subItems.length - 1];
      if (item1.labelLength >= 23 && item.labelLength < 23) {
        this.margintop = true;
      } else {
        this.margintop = false;
      }
    } else {
      this.margintop = false;
    }
    if ((item.labelLength >= 23 && prevItem && prevItem.labelLength >= 23) || (prevItem && prevItem.labelLength >= 23 && item.labelLength < 23)) {
      return true;
    } else {
      return false;
    }
  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }

  /**
   * Toggle settings-sidebar 
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }

  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add("open-sidebar-folded");
    }
  }

  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove("open-sidebar-folded");
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
  }

  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.value);
    this.document.body.classList.remove('settings-open');
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;
  }

  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }

  /**
   * Resets the menus
   */
  resetMenuItems() {
    const links = document.getElementsByClassName('nav-link-ref');
    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  };

  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      const usermgmt = window.location.pathname.slice(0, 9);
      var master = links[i]['pathname'].substring(9);
      var hod = window.location.pathname.slice(0, 13);
      var hod1 = hod.substring(9);
      if (usermgmt === '/usermgmt' && master !== "") {
        if (window.location.pathname.includes(master) && (links[i]['pathname'].includes(usermgmt)) && (window.location.pathname.includes(links[i]['pathname']))) {
          menuItemEl = links[i];
          break;
        }
      } else if ((window.location.pathname === links[i]['pathname'] || window.location.pathname.startsWith(links[i]['pathname'])) && usermgmt !== '/usermgmt') {
        menuItemEl = links[i];
        break;
      } else if (window.location.pathname === links[i]['pathname'] || (usermgmt === links[i]['pathname'] && window.location.pathname.includes('/usermgmt') && (master !== "" || hod1 === "/hod"))) {
        menuItemEl = links[i];
        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  };

  submitemwrap(link){
    if(link == '/aadhaarmismatchreport'){
      return 1;
    }else if(link == '/ekycstatusreport'){
      return 2;
    }
    else{
      return 0;
    }
  }

  sumitwrapmarigin(link) {
    if(link == '/ekycstatusreport'){
      return true;
    }else{
      false
    }
  }

  redirectionApiCall(){
    let instituteId = parseInt(this.userSessionService.getInstituteId());
    let route = `/api/Report/SSPfeeDetails/${instituteId}`
    
    let payload = {
      instituteId :instituteId
    }
    this.dataService.getAPIData(route, payload).subscribe({
      next: (res: any) => {
        const icon = res.isSuccess ? 'success' : 'error';                                                                             
        const message = res.isSuccess ? "Student Information saved successfully!!!" : res.htmlFormattedFailures;
        this.linkvalue = res.value;
      },
      error: (error) => {
        // this.toaster.error(`${error.status}` + ":" + `${error.statusText}`, "UMIS - Student Module");
      },
    });
  }

  redirectionGreivanceApiCall(){
    let instituteId = parseInt(this.userSessionService.getInstituteId());
    let route = `/api/Report/SSPFeeGrievance/${instituteId}`
    
    let payload = {
      instituteId :instituteId
    }
    this.dataService.getAPIData(route, payload).subscribe({
      next: (res: any) => {
        const icon = res.isSuccess ? 'success' : 'error';                                                                             
        const message = res.isSuccess ? "Student Information saved successfully!!!" : res.htmlFormattedFailures;
        this.grievanceLink = res.value;
      },
      error: (error) => {
        // this.toaster.error(`${error.status}` + ":" + `${error.statusText}`, "UMIS - Student Module");
      },
    });
  }

  clickExternal(link: string){
    
    if(link.split('/')[1] === 'fees'){
      window.open(this.linkvalue, '_blank');
    }
    else if(link.split('/')[1] === 'grievance'){
      window.open(this.grievanceLink, '_blank');
    }
    else{
      window.open(`#`, '_blank');
    }
  }

  clickSideBar(link: any) {
    this.pages.sharedData = 0;
    if (['/undergraduate', '/datavalidation', '/academicstatus', '/approvelstatus'].includes(link)) {
      this.pages.studentPage = false;
    }
    else if (['/dashboard'].includes(link)) {
      this.pages.districtView = false;
    }
    else if (['/course'].includes(link)) {
      this.pages.showCoursePage = false;
    }
    else if (['/usermgmt/user'].includes(link)) {
      debugger
      this.pages.showUserPage = false;
      this.pages.isEditConsolidatedData = 0;
      // console.log("..", this.pages.isEditConsolidatedData);
      
    }
    else if (['/usermgmt/department'].includes(link)) {
      this.pages.showDepartmentPage = false;
    }
    else if (['/usermgmt'].includes(link)) {
      this.pages.showHodPage = false;
    }
    else if (['/usermgmt/university'].includes(link)) {
      this.pages.showUniversityPage = false;
    }
    else if (['/usermgmt/institution'].includes(link)) {
      this.pages.showInstitutePage = false;
    }
    else if (['/usermgmt/addcourse'].includes(link)) {
      this.pages.showCourseCreationPage = false;
    }
    else if (['/usermgmt/branch'].includes(link)) {
      this.pages.showBranchPage = false;
    }
    else if (['/sanctionedseats'].includes(link)) {
      this.pages.sheetallocation = false
      // this.navigation.goToseatallocation();
    }
  }

  dashboardReload() {
    window.location.reload();
  }

}