import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "src/app/core/guard/auth.guard";
import { BaseComponent } from "./base/base.component";
import { HomeComponent } from "./home/home.component";


const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./../../views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      // {
      //   path: '', redirectTo: 'dashboard', pathMatch: 'full'
      // },
      {
        path: 'user',
        loadChildren: () => import('./../../views/pages/master/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'datavalidation',
        loadChildren: () => import('./../pages/student/student.module').then(m => m.StudentModule)
        // loadChildren: () => import('./../../views/pages/master/user/datavalidation-grid/datavalidation.module').then(m => m.datavalidationModule)
      },
      {
        path: 'profile/:id/:actionInfo',
        loadChildren: () => import('./../../views/pages/profile/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'schoolwisereport',
        loadChildren: () => import('./../../views/pages/reports/schoolwisereport/schoolwisereport.module').then(m => m.SchoolReportModule)
      },
      {
        path: 'wiseinstitutereport',
        loadChildren: () => import('./../../views/pages/reports/institute-wise-report/institute-wise.module').then(m => m.InstituteWiseReportModule)
      },
      {
        path: 'loginsummaryreport',
        loadChildren: () => import('./../../views/pages/reports/login-summary/login-summary.module').then(m => m.LoginsummaryReportModule)
      },
      {
        path: 'reportstudent',
        loadChildren: () => import('./../../views/pages/reports/student-report/student-report.module').then(m => m.StudentWiseReportModule)
      },
      {
        path: 'aadhaarmismatchreport',
        loadChildren: () => import('./../../views/pages/reports/aadhaarmismatched/aadhaarmismatched.module').then(m => m.AadhaarmismatchedModule)
      },
      {
        path: 'ekycstatusreport',
        loadChildren: () => import('./../../views/pages/reports/studentekycstatusreport/studentekycstatusreport.module').then(m => m.StudentekycstatusreportModule)
      },
      {
        path: 'loginreport',
        loadChildren: () => import('./../../views/pages/reports/studentloginreport/studentloginreport.module').then(m => m.StudentloginreportModule)
      },
      {
        path: 'sanctionedseats',
        loadChildren: () => import('./../../views/pages/courses/seatallcation/seatallcation.module').then(m => m.SeatallcationModule)
      },
      {
        path: 'bankverify',
        loadChildren: () => import('./../../views/pages/reports/bank-verification/bank-verification.module').then(m =>m.BankVerificationModule)
      },
      {
        path: 'anouncement',
        loadChildren: () => import('./../../views/pages/reports/announcement/announcement.module').then(m =>m.AnnouncementModule)
      },
      {
        path: 'scholarshipreport',
        loadChildren: () => import('./../../views/pages/reports/scholarshipstatus/scholarshipstatus.module').then(m =>m.ScholarshipstatusModule)
      },
      {
        path: 'pudhmaipennpayemt',
        loadChildren: () => import('./../../views/pages/reports/pudhmaipenreport/pudhmaipenreport.module').then(m =>m.PudhmaipenreportModule)
      },
      {
        path: 'tamizhpudhalavanpayemt',
        loadChildren: () => import('./../../views/pages/reports/tamizhpaymentreport/tamizhpaymentreport.module').then(m =>m.TamizhpaymentreportModule)
      },
      
      {
        path: 'studentinfo',
        loadChildren: () => import('./../../views/pages/studentinfomation/studentinfomation.module').then(m =>m.StudentinfomationModule)
      },
      
      { path: 'institute', loadChildren: () => import('./../pages/institute-old/institute/institute.module').then(m => m.InstituteModule) },
      { path: 'course', loadChildren: () => import('./../pages/courses/course.module').then(m => m.CourseModule) },
      { path: 'undergraduate', loadChildren: () => import('./../pages/student/student.module').then(m => m.StudentModule) },
      { path: 'manualuser', loadChildren: () => import('./../pages/userguide/userguide.module').then(m => m.UserguideModule) },
      { path: 'academicstatus', loadChildren: () => import('./../pages/student/student.module').then(m => m.StudentModule) },
      { path: 'approvelstatus', loadChildren: () => import('./../pages/student/student.module').then(m => m.StudentModule) },
      { path: 'usermgmt', loadChildren: () => import('./../../views/pages/user-management/user-management.module').then(m => m.UserManagementModule) },
      { path: 'manualuser', loadChildren: () => import('./../pages/userguide/userguide.module').then(m => m.UserguideModule) },
      { path: 'homepage', loadChildren: () => import('./../pages/temphome/temphome.module').then(m => m.TemphomeModule) },
      // {
      //   path: 'studentmodulecheck',
      //   loadChildren: () => import('./../../views/pages/studentmodulecheck/studentmodulecheck.module').then(m => m.StudentmodulecheckModule)
      // },

    ],
  },
  {
    path: 'home',
    component: HomeComponent
  }
]

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
