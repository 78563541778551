import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs/Rx";
import { environment } from "src/environments/environment";
import { dropdownData } from "../views/pages/app.interface";
import { CommonInfo } from "./../views/pages/app.config";
import * as _ from "lodash";

export interface IncomeCertificate {
  familyAnnualIncome: number | null;
  incomeCertificateESevaiResponseId: number | null;
  incomeCertificateNo: string | null;
  incomeDateOfExpiry: string | null;
  incomeVerifiedType: number;
}

@Injectable()
export class DataService {
  public commondata = CommonInfo;
  public baseUrl = environment.apiBaseUrl;
  public publicUrl = environment.apipublicUrl;
  public indiaId = environment.indiaId;
  send_data = new Subject<any>();

  private cache: any = {};
  public instituteDatafromAPI = new BehaviorSubject<any>({});
  public studentDatafromAPI = new BehaviorSubject<any>({});
  public studentId = new BehaviorSubject<any>(0);
  public coursesData = new BehaviorSubject<any>(this.commondata.courses);
  public courseData = new BehaviorSubject<any>({});

  // State Management

  public generalInst = new BehaviorSubject<any>(this.commondata.generalInstData);
  public contactInst = new BehaviorSubject<any>(this.commondata.contactInstData);
  public hoiInst = new BehaviorSubject<any>(this.commondata.hoiInstData);
  public nodalInst = new BehaviorSubject<any>(this.commondata.nodalInstData);

  // e-sevai
  private incomeCertificateNo = new BehaviorSubject<IncomeCertificate>({
    familyAnnualIncome: null,
    incomeCertificateESevaiResponseId: null,
    incomeCertificateNo: null,
    incomeDateOfExpiry: null,
    incomeVerifiedType: 0
  });



  // scholarship
  
  studentRoute = '/api/student/';

  constructor(private http: HttpClient) { }

  getData(route, refresh) {
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else {
      // no cached data or refresh requested
      return this.http.get<any>(this.publicUrl + route).map((response) => {
        this.cache[route] = response;
        return response;
      });
    }
  }

  getDataWithParams(route, params, refresh) {
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else {
      // no cached data or refresh requested
      return this.http.get<any>(this.baseUrl + route, { params: params }).map((response) => {
        this.cache[route] = response;
        return response;
      });
    }
  }
  
  getRecord(route) {
    return this.http.get<any>(this.baseUrl + route);
  }

  getRecordWithParams(route, params) {
    return this.http.get<any>(this.baseUrl + route, { params: params });
  }

  post(route, data) {
    return this.http.post<any>(this.publicUrl + route, data);
  }

  delete(route) {
    return this.http.delete(this.baseUrl + route).map((response) => {
      return response;
    });
  }

  getReport(route) {
    return this.http.get(this.baseUrl + route, { responseType: "blob" });
  }

  getExternalData(route) {
    return this.http.get<any>(route).map((response) => {
      return response;
    });
  }

  dataForRouteIsCached(route, refresh) {
    return this.cache[route] && (refresh === false || refresh === undefined);
  }

  clearCache() {
    this.cache = {};
  }

  clearRouteCache(route) {
    this.cache[route] = null;
  }

  // UMIS #############################################################################################################################

  getgeneralInstLocal() {
    return this.generalInst.asObservable();
  }

  getcontactInstLocal() {
    return this.contactInst.asObservable();
  }
  gethoiInstLocal() {
    return this.hoiInst.asObservable();
  }
  getnodalInstLocal() {
    return this.nodalInst.asObservable();
  }

  getinstituteDataLocal() {
    return this.instituteDatafromAPI.asObservable();
  }

  getstudentDataLocal() {
    return this.studentDatafromAPI.asObservable();
  }

  getStudentIdFromService() {
    return this.studentId.asObservable();
  }

  getAPI(route: string) {
    return this.http.get(this.publicUrl + route);
  }

  postAPI(route: string, payload) {
    return this.http.post<any>(this.publicUrl + route, payload);
  }

  getAPIData(route, refresh) {
    if (this.dataForRouteIsCached(route, refresh)) {
      return Observable.of(this.cache[route]);
    } else {
      // no cached data or refresh requested
      return this.http.get<any>(this.publicUrl + route).map((response) => {
        this.cache[route] = response;
        return response;
      });
    }
  }

  getDashboardDrillData(data: any, refresh: boolean) {
    return this.getAPIData('/api/dashboard/' + data.hodId + '/' + data.universityId + '/' + data.districtId + '/' + data.instituteOwnershipId + '/' + data.instituteTypeId + '/' + data.academicYearId + '/' + data.streamInfoId, refresh);
  }

  getInstituteData(data: any, refresh: boolean) {
    return this.getAPIData('/api/dashboard/dpdd/' + data.hodId + '/' + data.universityId + '/' + data.districtId + '/' + data.instituteOwnershipId + '/' + data.instituteTypeId + '/' + data.instituteId + '/' + data.academicYearId + '/' + data.categoryId, refresh);
  }

  getStudentStreamType1(instituteId:any,refresh: boolean) {
    return this.getAPIData("/api/lookup/stream/"+ instituteId, refresh);
  }

  
  getStudentStreamType(refresh: boolean) {
    return this.getAPIData("/api/lookup/streaminfolist", refresh);
  }
  getStudentscholarship(refresh: boolean) {
    return this.getAPIData("/api/lookup/ScholarshipStatus", refresh);
  }

  getStudentIntegrated(refresh: boolean) {
    return this.getAPIData("/api/lookup/YesNoType", refresh);
  }

  getCourseCategoryById(streamInfoId: any, courseType: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/CourseCategory/" + streamInfoId + "/" + courseType, refresh);
  }

  getCourseCategoryById1(instituteId:any,streamInfoId: any, courseType: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/CourseCategories/"+instituteId +"/"+ streamInfoId + "/" + courseType, refresh);
  }
  getCourseCategory(refresh: boolean) {
    return this.getAPIData("/api/lookup/CourseCategory", refresh);
  }

  getStatusTypeList(refresh: boolean) {
    return this.getAPIData("/api/lookup/StatusType", refresh);
  }

  getInstStatusTypeList(refresh: boolean) {
    return this.getAPIData("/api/lookup/InstituteStatus", refresh);
  }

  getMediumOfInstructionType(refresh: boolean) {
    return this.getAPIData("/api/lookup/mediumOfInstruction", refresh);
  }

  getStudentCourseType(instituteId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/coursetype" + "/" + instituteId, refresh);
  }

  getUniversityNameList(refresh: boolean) {
    return this.getAPIData("/api/lookup/universitylist", refresh);
  }

  getLoginType(aisheid: any, refresh: boolean) {
    return this.getAPIData("/api/user/LoginType/" + aisheid, refresh);
  }

  // /api/lookup/districtlookup/{stateId}
  getMasterList(placeName: any, parentId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/placelist/" + placeName + "/" + parentId, refresh);
    // return this.getAPIData("/api/lookup/districtlookup/" + stateId , refresh);
  }

  gethoiinstitutedata(instituteid: any) {
    return this.getAPI("/api/nodalofficer/headofinstitutedetails/" + instituteid)
  }

  getcorporationList(urbanType: any, districtId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/corporationlookup/" + urbanType + "/" + districtId, refresh);
  }
  getzoneList(zoneId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/zonelookup/" + zoneId, refresh);
  }
  getwardList(corporationId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/wardlookupbycorporation/" + corporationId, refresh);
  }
  getwardListbyzoneId(zoneId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/wardlookupbyzone/" + zoneId, refresh);
  }
  getvillagePanchayatList(blockId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/villagePanchayatlookup/" + blockId, refresh);
  }
  getState(countryId, refresh) {
    return this.getAPIData("/api/lookup/statelookup/" + countryId, refresh);
  }
  getDistrict(stateId, refresh) {
    return this.getAPIData("/api/lookup/districtlookup/" + stateId, refresh);
  }

  getDistrictByUniversity(universityId, refresh) {
    return this.getAPIData("/api/district/" + universityId, refresh);
  }

  getTaluk(districtId, refresh) {
    return this.getAPIData("/api/lookup/taluklookup/" + districtId, refresh);
  }

  getVillage(talukId, refresh) {
    return this.getAPIData("/api/lookup/villagelookup/" + talukId, refresh);
  }

  getBlocklookup(parentId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/blocklookup/" + parentId, refresh);
  }

  getBlockFromEMISlookup(districtId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/Emisblocklookup/" + districtId, refresh);
  }

  getSchoolType(blockId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/EmisSchoolTypelookup/" + blockId, refresh);
  }

  getSchoolName(blockId: any, EMISSchoolType: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/EmisSchoolUdiscCodelookup/" + blockId + '/' + EMISSchoolType, refresh);
  }
  
  // /api/student/FindmyumisSearch
  
  getStudentdetailsbydatas(data: any){
    return this.postAPI("/api/student/FindmyumisSearch", data)
  }

  // /api/student/Findmyumisid/{umisId}

  getstudentdetailsbyknownumisid(umisId: any, refresh: any){
    debugger
    return this.getAPIData("/api/student/Findmyumisid/" + umisId, refresh)
  }

  // /api/InstituteSeatAllocation
  getInstituteSeatAllocation(data: any){
    return this.postAPI("/api/InstituteSeatAllocation", data)
  }

  // /api/InstituteSeatAllocation/{id}

  getInstituteSeatAllocationbyid(id, refresh: any){
    debugger
    return this.getAPIData("/api/InstituteSeatAllocation/SeatAllocation/" + id, refresh)
  }

  // getvillagePanchayatlookup(parentId: any, refresh: boolean) {
  //   return this.getAPIData("/api/lookup/villagePanchayatlookup/" + parentId, refresh);
  // }
  // getcorporationlookup(urbanType:any, districtId: any, refresh: boolean) {
  //   return this.getAPIData("/api/lookup/corporationlookup/" + urbanType + "/"  + districtId, refresh);
  // }
  // getzonelookup(urbanType:any, districtId: any, refresh: boolean) {
  //   return this.getAPIData("/api/lookup/corporationlookup/" + districtId, refresh);
  // }
  getDepartmentList(refresh: boolean) {
    return this.getAPIData("/api/lookup/governmentdepartment", refresh);
  }

  getHodDropdownList(departmentId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/hodlist/" + departmentId, refresh);
  }



  getHodList(departmentid: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/hodlist/" + departmentid, refresh);
  }

  getdepartment(refresh: boolean) {
    return this.getAPIData("/api/governmentdepartment/lookup", refresh);
  }

  getUniversityList(hodId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/university/" + hodId, refresh);
  }
  getuniversitylist(refresh: any) {
    return this.getAPIData("/api/lookup/universitylist", refresh)
  }

  getInstutionList(refresh: any) {
    return this.getAPIData("/api/lookup/InstituteCategoryList", refresh)
  }

  getStreamList(data: any, refresh: any) {
    return this.getAPIData("/api/lookup/streaminfolist/" + data.hodId + '/' + data.universityId + '/' + data.districtId + '/' + data.instituteId, refresh)
  }

  getInstituteList(unversityId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/institute/" + unversityId, refresh);
  }

  getInstByUnivDistrict(unversityId: any, districtId: any, refresh: boolean) {
    return this.getAPIData("/api/institute/instituteList/" + unversityId + '/' + districtId, refresh);
  }

  getInstByUnivDistrictInsType(unversityId: any, districtId: any, instituteTypeId: any, refresh: boolean) {
    return this.getAPIData("/api/institute/instituteList/" + unversityId + '/' + districtId + '/' + instituteTypeId, refresh);
  }

  getInstByUnivDistrictOwnerInsType(unversityId: any, districtId: any, instituteOwnershipId: any, instituteTypeId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/institute/" + unversityId + '/' + districtId + '/' + instituteOwnershipId + '/' + instituteTypeId, refresh);
  }

  getInstOwnerShip(unversityId: any, districtId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/InstituteOwnership/" + unversityId + '/' + districtId, refresh);
  }

  getInstType(unversityId: any, districtId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/instituteType/" + unversityId + '/' + districtId, refresh);
  }

  getInstTypeByOwner(unversityId: any, districtId: any, instituteOwnershipId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/instituteType/" + unversityId + '/' + districtId + '/' + instituteOwnershipId, refresh);
  }

  getAcademicByInstittute(institutionId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/academicYear/" + institutionId, refresh);
  }

  // /api/lookup/institutelist/{districtId}/{streamInfoId}

  getInstitutelist(districtId: any, streamInfoId: any, refresh: any){
    return this.getAPIData('/api/lookup/institutelist/' + districtId + '/' + streamInfoId, refresh)
  }

  // InstituteCategoryList(refresh: boolean) {
  //   return this.getAPIData('/api/institute/InstituteCategoryList', refresh);
  // }

  InstituteOwnerList(refresh: boolean) {
    return this.getAPIData("/api/institute/InstituteOwnerList", refresh);
  }

  // getStudentList(refresh: boolean) {
  //   return this.getAPIData('/api/student/students', refresh);
  // }

  getOtp(payload: any, refresh: boolean) {
    return this.http.post<any>(this.publicUrl + "/api/aadhaarverification/generateotp", payload)
  }

  getOtpHod(payload: any, refresh: boolean) {
    return this.http.post<any>(this.publicUrl + "/api/aadhaarVerification/generateOtpForInstitute", payload)
  }

  getOtpForLogin(data: any, refresh: boolean) {
    return this.getAPIData("/api/generateotp/" + data.username, refresh);
  }

  getStudentList(apiUrl: string, data: any, refresh: boolean) {
    return this.getAPIData("/api/student/" + apiUrl + '/' + data.InstituteId + "/" + data.AcademicYear + "/" + data.CourseType + "/" + data.CourseId + "/" + data.BranchSpecializationId + '/' + data.isDataValidation, refresh);
  }

  getListFromEMIS(data: any, refresh: boolean) {
    return this.getAPIData("/api/student/Findmyemisid/" + data.UdiseCode + "/" + data.Dob + "/" + data.Name, refresh);
  }

  getStudentListByInstitute(institutionId: any, academicYearId: any, streamInfoId: any, isDataValidation, refresh: boolean) {
    return this.getAPIData("/api/student/dpstudent/" + institutionId + "/" + academicYearId + "/" + streamInfoId + '/' + isDataValidation, refresh);
  }

  getdatavalidationList(data: any, refresh: boolean) {
    return this.getAPIData("/api/student/importedstudent/" + data.InstituteId + "/" + data.AcademicYear + "/" + data.CourseId + "/" + data.BranchSpecializationId, refresh);
  }

  getStudentId(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/" + studentId, refresh);
  }

  getStudentGeneralById(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/allstudentgeneralinformation/" + studentId, refresh);
  }

  getStudentContactById(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/studentcontact/allstudentcontactdetails/" + studentId, refresh);
  }
  getContuctById(instituteid: any, refresh: boolean) {
    return this.getAPIData("/api/institutecontact/institutecontactdetails/" + instituteid, refresh);
  }
  getStudentFamilyById(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/studentfamilyinfo/studentfamilyinfo/" + studentId, refresh);
  }

  getStudentAcademicById(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/studentacademicinfo/studentacademicinfo/" + studentId, refresh);
  }

  getStudentBankById(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/studentbankaccount/getallbystudentid/" + studentId, refresh);
  }

  getStudentInfomation(referenceNo: any,dob:any, refresh: boolean) {
    return this.getAPIData("/api/student/studentinfo/EmisOrUmis/" + referenceNo + '/' + dob, refresh);
  }
  getStudentInfobyId(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/studentinfo/" + studentId, refresh);
  }

  getStudentGeneralInfobyId(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/studentinfo/general/" + studentId, refresh);
  }

  getStudentContactInfobyId(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/studentinfo/contact/" + studentId, refresh);
  }

  getStudentAcademicInfobyId(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/studentinfo/academic/" + studentId, refresh);
  }

  getStudentFamilyInfobyId(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/studentinfo/family/" + studentId, refresh);
  }

  getStudentBankInfobyId(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/studentinfo/bank/" + studentId, refresh);
  }

  getSpecialCategoryId(studentId: any, refresh: boolean) {
    return this.getAPIData("/api/student/specialcategory/" + studentId, refresh);
  }

  getNationality(refresh: boolean) {
    return this.getAPIData("/api/lookup/nationality", refresh);
  }

  getcommunityList(refresh: boolean) {
    return this.getAPIData("/api/community/communityList", refresh);
  }

  getcountrylist(refresh: boolean) {
    return this.getAPIData("/api/lookup/countrylist", refresh);
  }
  getReligionType(refresh: boolean) {
    return this.getAPIData("/api/lookup/religion", refresh);
  }

  // getCasteType(CommunityId: any, CasteId: any, refresh: boolean) {
  //   return this.getAPIData('/api/course/caste/' + CommunityId + '/' + CasteId, refresh);
  // }

  getCasteType(CommunityId: any, refresh: boolean) {
    return this.getAPIData("/api/community/casteList/" + CommunityId, refresh);
  }

  getEMISNAResonsAPI(refresh: boolean) {
    return this.getAPIData("/api/lookup/EMISNAReasons", refresh);
  }

  getCourseTypeAll(id: any, refresh: boolean) {
    return this.getAPIData("/api/course/course/" + id, refresh);
  }

  getCourseTypeByStreamType(streamInfoId: any) {
    return this.http.get<any>(this.publicUrl + "/api/lookup/courseTypes/" + streamInfoId);
  }

    getCourseTypeByStreamType1(instituteId:any,streamInfoId: any) {
    return this.http.get<any>(this.publicUrl + "/api/lookup/courseTypes/"+ instituteId +"/"+ streamInfoId);
  }

  getCourseType(InstituteId: any, refresh: boolean) {
    return this.getAPIData("/api/instituteCourse/Courses/" + InstituteId, refresh);
  }

  getCourseBycourseType(instituteId: any, streamInfoId: any, courseType: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/course/" + instituteId + '/' + streamInfoId + '/' + courseType, refresh);
  }

  getCourseBycourseCategory(instituteId: any, streamInfoId: any, courseType: any, CourseCategory: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/course/" + instituteId + '/' + streamInfoId + '/' + courseType + '/' + CourseCategory, refresh);
  }

  getCourseBycourseCategory1(instituteId: any, streamInfoId: any, courseType: any, CourseCategory: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/coursess/" + instituteId + '/' + streamInfoId + '/' + courseType + '/' + CourseCategory, refresh);
  }

  getStatusType(apiName: string, refresh: boolean) {
    return this.getAPIData(apiName, refresh);
  }

  getSubjectTypeAll(courseid: any, refresh: boolean) {
    return this.getAPIData("/api/course/CourseBranch/" + courseid, refresh);
  }
  getSubjectTypeAll1(instituteId:any,streamInfoId:any,courseType:any,CourseCategory:any,courseid: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/CourseBranches/"+ instituteId + '/' + streamInfoId + '/' + courseType + '/' + CourseCategory+'/'+ courseid, refresh);
  }
  getSubjectType(InstituteId: any, courseid: any, refresh: boolean) {
    return this.getAPIData("/api/instituteCourse/CourseBranch/" + InstituteId + '/' + courseid, refresh);
  }

  gethostelType(refresh: boolean) {
    return this.getAPIData("/api/lookup/hoteltype", refresh);
  }

  getFromIfsc(ifsc: any, refresh: boolean) {
    return this.getAPIData("/api/studentbankaccount/getbyifsc/" + ifsc, refresh);
  }

  getDropdownData(enumType: number, languageType: number) {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/enumlist/" + `${enumType}` + "/" + `${languageType}`).subscribe({
      next: (res: any) => {
        if (languageType === 1) {
          switch (enumType) {
            //  Institute - general
            // case 17: {
            //   this.commondata.instituteType = res;
            //   break;
            // }
            case 18: {
              this.commondata.instituteCategory = res;
              break;
            }
            // case 19: {
            //   this.commondata.instOwnership = res;
            //   break;
            // }
            case 100: {
              this.commondata.univName = res;
              break;
            }
            case 100: {
              this.commondata.yearOfEstablishmentId = res;
              break;
            }
            case 23: {
              this.commondata.univType = res;
              break;
            }
            //  Institute - additional

            case 24: {
              this.commondata.accountType = res;
              break;
            }

            //  Institute - HOI , Nodal Officer

            case 21: {
              this.commondata.salutation = res;
              break;
            }
            case 22: {
              this.commondata.designation = res;
              break;
            }
            case 4: {
              res = _.orderBy(res, ["key"], ["asc"]);
              this.commondata.gender = res;
              break;
            }

            //  Student - general

            case 26: {
              let result = res.filter((obj, index, self) => {
                return index === self.findIndex((o) => o.value === obj.value);
              });
              result = _.orderBy(result, ["value"], ["asc"]);
              this.commondata.bloodGroup = result;
              break;
            }
            //  Student - family
            case 27: {
              this.commondata.fatherOccupation = res;
              this.commondata.motherOccupation = res;
              this.commondata.guardianOccupation = res;
              break;
            }
            case 28: {
              this.commondata.familyAnnualIncome = res;
              break;
            }
            case 29: {
              this.commondata.familyCardType = res;
              break;
            }

            //  Student - academic

            case 30: {
              this.commondata.modeOfStudy = res;
              break;
            }
            case 31: {
              this.commondata.typeOfAdmission = res;
              break;
            }
            case 35: {
              this.commondata.remarks = res;
              break;
            }
            // case 100: {
            //   this.commondata.courseId = res;
            //   break;
            // }
            // case 100: {
            //   this.commondata.courseSpecializationId = res;
            //   break;
            // }
            default: {
              console.log("NO DROP DOWN DATA AVAILABLE");
              break;
            }
          }
        }

        // if (enumType == 17) {
        //   this.commondata.univType = res;
        // }
        // else if (enumType == 18) {
        //   this.instOwnership = res;
        // }
        // else if (enumType == 19) {
        //   this.instituteCategory = res;
        // }
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getStateList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/placelist/State/1").subscribe({
      next: (res: any) => {
        this.commondata.state = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getAcademicYearList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/academicyear").subscribe({
      next: (res: any) => {
        debugger
        let data = JSON.stringify(res);
        data = JSON.parse(data);
        this.commondata.academicYearId = data;
        this.commondata.academicYearAll = res;
        const year = { key: 0, value: 'All Academic Year' };
        this.commondata.academicYearAll.unshift(year);
        
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  AcademicYearListbyStudentId(studentid: any){
    return this.http.get<any>(this.publicUrl + "/api/lookup/academicyearstd/" + `${studentid}`);
  }

  getInstituteAcademicYearList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/Instituteestablishedyear").subscribe({
      next: (res: any) => {
        this.commondata.instituteAcademicYearList = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getAcademicStatusList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/AcademicStatusType").subscribe({
      next: (res: any) => {
        this.commondata.academicStatusList = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getCountryList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/countrylist").subscribe({
      next: (res: any) => {
        const index = res.findIndex((x) => x.key === this.indiaId);
        if (index > -1) {
          res.splice(index, 1);
        }
        this.commondata.allCountry = res;
        this.commondata.countryIndia = [{ key: this.indiaId, value: "India" }];
        this.commondata.country = [
          { key: this.indiaId, value: "India" },
          { key: 0, value: "Others" },
        ];
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getInstituteCategoryList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/InstituteCategoryList").subscribe({
      next: (res: any) => {
        this.commondata.instituteCategoryList = [];
        this.commondata.instituteCategoryList = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getStudentTotal(hodid: any, unversityid: any, instituteid: any, districtid: any, academicyear: any, areatype: any, refresh: any) {
    return this.getAPIData("/api/student/studenttotal/" + hodid + "/" + unversityid + "/" + instituteid + "/" + districtid + "/" + academicyear + "/" + areatype, refresh);
  }

  getCommunityTotal(hodid: any, unversityid: any, instituteid: any, districtid: any, academicyear: any, areatype: any, refresh: any) {
    return this.getAPIData("/api/student/studentcommunitychart/" + hodid + "/" + unversityid + "/" + instituteid + "/" + districtid + "/" + academicyear, refresh);
  }

  getHoDs() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/governmentdepartment").subscribe({
      next: (res: any) => {
        this.commondata.hodList = [];
        this.commondata.hodList = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getInstitute(universityId: any) {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/institute/" + universityId).subscribe({
      next: (res: any) => {
        this.commondata.instituteList = [];
        this.commondata.instituteList = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getUniversity(hodId: any) {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/university/" + hodId).subscribe({
      next: (res: any) => {
        this.commondata.universityList = [];
        this.commondata.universityList = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getInstituteTypeList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/institutetype").subscribe({
      next: (res: any) => {
        this.commondata.instituteType = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getDisabilityTypeList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/disability").subscribe({
      next: (res: any) => {
        this.commondata.disabilityType = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getSpecialcategory() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/specialCategory").subscribe({
      next: (res: any) => {
        res.forEach((e) => {
          e.specialCategoryId = e.key;
        });
        res = _.orderBy(res, ["value"], ["asc"]);
        this.commondata.SpecialCategory = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getInstituteOwnerList() {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/lookup/InstituteOwnerList").subscribe({
      next: (res: any) => {
        this.commondata.instOwnership = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  getCourseList(instituteId: any) {
    this.http.get<dropdownData[]>(this.publicUrl + "/api/instituteCourse/institutecourse/" + `${instituteId}`).subscribe({
      next: (res: any) => {
        this.commondata.courseGrid = res;
      },
      error: (error) => {
        console.log("testerror : ", error);
      },
    });
  }

  // Course Data

  getcoursesDataLocal() {
    return this.coursesData.asObservable();
  }
  getcourseDataLocal() {
    return this.courseData.asObservable();
  }

  getCourseData() {
    return this.http.get<any>(this.publicUrl + "/api/course/course/0");
  }

  getCourseEditData(id: any, instituteId: any, courseId: any) {
    return this.http.get<any>(this.publicUrl + "/api/instituteCourse/ListCourses/" + `${id}` + "/" + `${instituteId}` + "/" + `${courseId}`);
  }

  getBranchData(courseId: number) {
    return this.http.get<any>(this.publicUrl + "/api/course/CourseBranch/" + `${courseId}`);
  }

  postAddCourse(payLoad: any) {
    return this.http.post<any>(this.publicUrl + "/api/instituteCourse", payLoad);
  }

  // postcall nodelofficer
  postnodelofficer(payload: any) {
    return this.http.post<any>(this.publicUrl + "/api/nodalofficer/savenodalofficer", payload)
  }

  getEmisData(emisId: any) {
    return this.http.get<any>(this.publicUrl + "/api/student/emis/" + `${emisId}`);
  }

  getheadofinstitutedetails(instituteid: any) {
    return this.http.get<any>(this.publicUrl + "/api/nodalofficer/headofinstitutedetails/" + instituteid);
  }

  nodalofficerdetails(instituteid: any) {
    return this.http.get<any>(this.publicUrl + "/api/nodalofficer/nodalofficerdetails/" + instituteid)
  }

  saveStudentEmisJson(payload) {
    return this.http.post<any>(this.publicUrl + "/api/studentemisjson/saveStudentEmisJson", payload)
  }

  aadhaarVerification(payload) {
    return this.http.post<any>(this.publicUrl + "/api/aadhaarVerification/doKycWithOtp", payload)
  }
  aadhaarVerificationHod(payload) {
    return this.http.post<any>(this.publicUrl + "/api/aadhaarVerification/doKycWithOtpForInstitute", payload)
  }

  loginOtpVerification(payload) {
    return this.http.post<any>(this.publicUrl + "/api/verifyloginotp", payload)
  }

  studentAadharVerification(payload) {
    return this.http.post<any>(this.publicUrl + "/api/student/updateaadhaarverificationstatus", payload)
  }
  
  studentAadharVerificationHod(payload) {
    return this.http.post<any>(this.publicUrl + "/api/aadhaarVerification/UpdateInstituteAadhaarVerificationStatus", payload)
  }

  saveEMISandIsVerified(payload) {
    return this.http.post<any>(this.publicUrl + "/api/student/SaveEmisVerified", payload)
  }


  studentListForSpecialCatagory(data){
    return this.http.get<any>(this.publicUrl + '/api/student/studentListForSpecialCatagory/' + data.instituteId + '/' + data.academicYearId + '/'
     + data.courseId + '/' + data.courseSpecializationId +'/' + data.studentSpecialCatagoryType);
  }
  // /api/student/studentUpdateByHodAndUniversity

  savestudentUpdateByHodAndUniversity(payload) {
    return this.http.post<any>(this.publicUrl + "/api/student/studentUpdateByHodAndUniversity", payload)
  }

  getCourseByinstituteId(instituteId: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/InstituteCourseList/" + instituteId, refresh);
  }

  getSubjectTypeinstituteandcourse(instituteId: any, courseid: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/InstituteCourseBranchList/" + instituteId + '/' + courseid, refresh);
  }

  // /api/lookup/InstituteCourseList/{instituteId}/{CourseType}

  getcourseType(instituteId: any, CourseType: any, refresh: boolean) {
    return this.getAPIData("/api/lookup/InstituteCourseList/" + instituteId + '/' + CourseType, refresh);
  }


  // /api/student/studentList/{instituteId}/{academicYearId}/{courseId}/{courseSpecializationId}/{isDataValidation}

  // /api/student/studentListForSpecialCatagory/{instituteId}/{academicYearId}/{CourseType}/{courseId}/{courseSpecializationId}/{studentSpecialCatagoryType}

  getstudentlistgrid(data, refresh: boolean) {
    return this.getAPIData("/api/student/studentListForSpecialCatagory/" + data.instituteId + '/' + data.academicYearId + '/' + data.CourseType +
    '/' + data.courseId + "/" + data.courseSpecializationId + '/' + data.studentSpecialCatagoryType, refresh);
  }

  savestudentBankDetails(payload) {
    return this.http.post<any>(this.publicUrl + "/api/bankAccount/StatusCheckAPB", payload)
  }

  getBankdetails(addarNo:any,mobile:any, refresh: boolean) {
    return this.getAPIData("/api/NPCI/getAadhaarStatus/" + addarNo + '/' + mobile, refresh);
  }


  
  // scholarship

  studentInfo() {
    return this.getData(
      `${this.studentRoute}studentinfo/getTPEligible`,true
    );
  }

  getAcademicYear() {
    return this.getData(
      "/api/lookup/academicyear",false
    );
  }
  
  getRegstionpage(InstituteId: any, refresh: boolean) {
    return this.getAPIData("/api/InstituteSeatAllocation/seatFilled/" + InstituteId, refresh);
  }

  getUKUTDashboard(instituteId: any, refresh: boolean) {
    return this.getAPIData(`/api/Report/RedirectionSSPfeeDetails/${instituteId}`, refresh);
  }

  scholarshipReportListAndCount(data){
    return this.postAPI("/api/dashboard/ExcelExportReportForDashboard", data)
  }

  geteligibleReasonlist(refresh: boolean) {
    return this.getAPIData("/api/lookup/reason", refresh);
  }
  postdropdownValues(payLoad: any) {
    return this.http.post<any>(this.publicUrl + "/api/student/SaveInEligibility", payLoad);
  }

  tamilpudhalavnreport(genderId: any, refresh: boolean) {
    return this.getAPIData("/api/dashboard/ExcelReportForPayment/" + genderId, refresh);
  }

  getOtpForProceed(refresh: boolean) {
    return this.getAPIData("/api/user/NodalOfficerGetOTP", refresh);
  }
  getStudentListEdmstudentcount(apiUrl: string, data: any, refresh: boolean) {
    return this.getAPIData("/api/student/" + apiUrl + '/' + data.InstituteId + "/" + data.AcademicYear + "/" + data.CourseType + "/" + data.CourseId + "/" + data.BranchSpecializationId + '/' + data.isDataValidation, refresh);
  }

  getStudentListEdm(apiUrl: string, data: any, refresh: boolean) {
    return this.getAPIData("/api/student/" + apiUrl + '/' + data.InstituteId + "/" + data.AcademicYear + "/" + data.CourseType + "/" + data.CourseId + "/" + data.BranchSpecializationId + '/' + data.isDataValidation +'/' + data.pageSize +'/' + data.pageNo, refresh);
  }

// e-sevai
getIncomeCertificateNumber(type) {
  if(type === 'income'){
    return this.incomeCertificateNo.asObservable();
  }
  else{
    return this.incomeCertificateNo.asObservable();
  }
  
}

emitValue(type, value: any) {
  console.log(value, 'santhiya')
  // if(type === 'income'){
    this.incomeCertificateNo.next(value);

  // }
  
}

getCurrentValue(type) {
  // if(type === 'income'){
    return this.incomeCertificateNo.getValue();

  // }
}

}
// data.pageNo